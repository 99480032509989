import React from 'react';

import { useQuery } from '@apollo/client';

import NavDrop from '../NavDrop';
import Link from '../Link';

import { FOOTER } from '../../cms/home/queryConstants';
import { NAV_DROP, NAV_ITEM } from '../../constants/constants';
import { showButton } from '../../helpers/utils';

const Footer = () => {

    const { data, loading } = useQuery(FOOTER);

    const apiData = data?.footer?.data?.attributes;

    const handleClick = () => {
        window.location.href = window.oneLinkRedirectionLink;
    }

    if (loading) {
        return (<></>)
    }
    const getNav = (item, iter) => {
        switch (item?.type) {
            case NAV_ITEM:
                return (
                    <Link
                        to={item?.link}
                        className="footer__nav-item"
                        key={`nav-item-${iter}`}
                    >
                        {item?.label}
                    </Link>
                );
            case NAV_DROP:
                return (
                    <NavDrop
                        title={item?.label}
                        isExpanded={false}
                        id={item?.label}
                        key={`nav-drop-${iter}`}
                    >
                        {item?.options?.map((bullet, idx) => {
                            return (
                                <Link
                                    key={`nav-drop-item-${idx}`}
                                    to={bullet?.link}
                                    target="_blank"
                                    rel='noreferrer'
                                    className="footer__nav-item"
                                >
                                    {bullet?.label}
                                </Link>
                            )
                        })}
                    </NavDrop>
                );
        }
    }

    return (
        <div className='footer'>
            <div className="footer__top">
                <div className="footer__left">
                    <img
                        src="https://www.zolveimages.zolve.com/website/images/logo_dark_bg.svg"
                        alt=""
                        className="footer__logo"
                    />
                    {showButton() &&
                        (
                            <button
                                className='header__cta footer__cta '
                                onClick={handleClick}
                            >
                                {apiData?.mobile_cta}
                            </button>
                        )}
                    <div className="footer__nav-container">
                        {apiData?.data?.data?.map((item, idx) => {
                            return (
                                <div key={`footer-nav-${idx}`} className="footer__nav">
                                    <p className="footer__nav-title">
                                        {item?.title}
                                    </p>
                                    <div className="footer__nav-items">
                                        {item?.items?.map((card, iter) => {
                                            return getNav(card, iter)
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="footer__right">

                </div>
            </div>
            <div className="footer__bottom">
                <div className="footer__disclaimer">
                    <p className="footer__disclaimer-title">
                        {apiData?.disclaimer?.title}
                    </p>
                    {apiData?.disclaimer?.content?.map((item, idx) => {
                        return (
                            <p
                                key={`footer-disclaimer-${idx}`}
                                className="footer__disclaimer-content"
                            >
                                {item}
                            </p>
                        )
                    })}

                </div>
                <div className="footer__info">
                    {apiData?.location?.location.map((item, idx) => {
                        return (
                            <div
                                className={`footer__location footer__location--${item?.style}`}
                                key={`footer-location-${idx}`}
                            >
                                <p
                                    className="footer__location-name "
                                >
                                    {item?.title}
                                </p>
                                <img
                                    src={item?.image}
                                    alt=""
                                    className="footer__location-img "
                                />
                            </div>
                        )
                    })}

                </div>
            </div>
            <div className="footer__social">
                <p className="footer__social-title">
                    {apiData?.social?.title}
                </p>
                {apiData?.social?.options?.map((item, idx) => {
                    return (
                        <a
                            href={item?.link}
                            key={`footer-social-${idx}`}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={item?.image}
                                alt="" className="footer__social-img"
                            />
                        </a>
                    )
                })}
            </div>
        </div>
    )
}

export default Footer