import React from 'react';

export default function ParseHTML({ string }) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(string, 'text/html');
    const styles = Array?.from(parsedHtml?.head?.querySelectorAll('style'))?.map(styleTag => styleTag?.textContent)?.join('\n');
    const serializer = new XMLSerializer();
    const htmlString = serializer.serializeToString(parsedHtml.body);

    return (
        <div>
            <style dangerouslySetInnerHTML={{ __html: styles }} />
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        </div>
    );
}