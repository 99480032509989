exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bank-account-js": () => import("./../../../src/pages/bank-account.js" /* webpackChunkName: "component---src-pages-bank-account-js" */),
  "component---src-pages-blog-blog-category-blog-name-js": () => import("./../../../src/pages/blog/[blogCategory]/[blogName].js" /* webpackChunkName: "component---src-pages-blog-blog-category-blog-name-js" */),
  "component---src-pages-blog-blog-category-index-js": () => import("./../../../src/pages/blog/[blogCategory]/index.js" /* webpackChunkName: "component---src-pages-blog-blog-category-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-credit-card-js": () => import("./../../../src/pages/credit-card.js" /* webpackChunkName: "component---src-pages-credit-card-js" */),
  "component---src-pages-edge-js": () => import("./../../../src/pages/edge.js" /* webpackChunkName: "component---src-pages-edge-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-insurance-type-js": () => import("./../../../src/pages/insurance/[insuranceType].js" /* webpackChunkName: "component---src-pages-insurance-insurance-type-js" */),
  "component---src-pages-loan-loan-type-js": () => import("./../../../src/pages/loan/[loanType].js" /* webpackChunkName: "component---src-pages-loan-loan-type-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-scholarship-jsx": () => import("./../../../src/pages/scholarship.jsx" /* webpackChunkName: "component---src-pages-scholarship-jsx" */),
  "component---src-pages-sim-card-js": () => import("./../../../src/pages/sim-card.js" /* webpackChunkName: "component---src-pages-sim-card-js" */),
  "component---src-pages-student-loan-js": () => import("./../../../src/pages/student-loan.js" /* webpackChunkName: "component---src-pages-student-loan-js" */),
  "component---src-pages-terms-and-condition-[tandc]-js": () => import("./../../../src/pages/terms-and-condition/[tandc].js" /* webpackChunkName: "component---src-pages-terms-and-condition-[tandc]-js" */),
  "component---src-pages-terms-and-condition-index-js": () => import("./../../../src/pages/terms-and-condition/index.js" /* webpackChunkName: "component---src-pages-terms-and-condition-index-js" */),
  "component---src-pages-us-predeparture-orientation-js": () => import("./../../../src/pages/us-predeparture-orientation.js" /* webpackChunkName: "component---src-pages-us-predeparture-orientation-js" */),
  "component---src-pages-visa-assistance-js": () => import("./../../../src/pages/visa-assistance.js" /* webpackChunkName: "component---src-pages-visa-assistance-js" */),
  "component---src-pages-visa-folder-js": () => import("./../../../src/pages/visa-folder.js" /* webpackChunkName: "component---src-pages-visa-folder-js" */),
  "component---src-pages-visa-slot-js": () => import("./../../../src/pages/visa-slot.js" /* webpackChunkName: "component---src-pages-visa-slot-js" */)
}

