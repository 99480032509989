import { BLACKLISTED_PATH } from "../constants/constants";

export const isBrowser = () => typeof window !== "undefined"

function utmParameters() {
    const params = new URLSearchParams(window.location.search);
    const utm_payload = {};
    const utm_params = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_affiliate",
        "utm_keyword",
        "utm_term",
        "utm_channel",
        "utm_source_platform",
        "utm_marketing_tactic",
        "fbclid",
        "referral_code",
        "deep_link_sub1",
        "deep_link_sub2",
        "deep_link_sub3",
        "deep_link_sub4",
        "deep_link_sub5",
        "deep_link_sub6",
        "deep_link_sub7",
        "deep_link_sub8",
        "deep_link_sub9",
        "deep_link_sub10",
    ];
    for (const param of utm_params) {
        utm_payload[param] = params.get(param);
    }
    return utm_payload;
}

export function getCookie(name) {
    var cookieFullList = document.cookie.split(";");

    for (var cookie = 0; cookie < cookieFullList.length; cookie++) {
        var cookiePair = cookieFullList[cookie].split("=");

        // Removing whitespace
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value(if need be)
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

export const getMobileOS = () => {
    const ua = navigator.userAgent.toLowerCase();
    if (/android/i.test(ua)) {
        return { isAndroid: true };
    } else if (/ipad|iphone|ipod/.test(ua)) {
        return { isIos: true };
    }
    return { isIos: false, isAndroid: false };
};

export const showButton = () => {
    if (isBrowser()) {
        const path = window.location.pathname.split("/");
        if (BLACKLISTED_PATH?.includes(path.at(-2))) {
            return false
        }
        else {
            return true
        }
    }
}

function generateUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    );
}

function getRakutenQueryParams() {
    const params = new URLSearchParams(window.location.search);
    const rakutenQueryParams = {};
    const rakutenKeys = ["ranMID", "ranEAID", "ranSiteID"];
    for (const param of rakutenKeys) {
        rakutenQueryParams[param] = params.get(param);
    }
    return rakutenQueryParams;
}

export function storeUtmProperties() {
    const utmProperties = utmParameters();
    const domain = ".zolve.com";
    const d = new Date();
    const exdays = 90;

    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();

    const timeEntered = Date.now().toString();
    let utmPropertiesExists = true;
    for (const [key, value] of Object.entries(utmProperties)) {
        if (value && value !== getCookie(key)) {
            utmPropertiesExists = false;
        }
        if (value) {
            if (key === 'fbclid') {
                document.cookie = `_fbc=${value};${expires};domain=${domain}; path=/`;
            }
            if (value === "gigresearch") {
                document.cookie = `referral_code=AzpireNew20;${expires};domain=${domain}; path=/`;
            }
            document.cookie = `${key}=${value};${expires};domain=${domain}; path=/`;
        }
    }
    if (!utmPropertiesExists) {
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 90);
        document.cookie = `utm_time_entered=${timeEntered};domain=${domain};expires=${expiryDate}`;
    }
}

export function storeRakutenProperties() {
    const rakutenQueryParams = getRakutenQueryParams();
    const domain = ".zolve.com";
    const timeEntered = Date.now().toString();
    const UUID = generateUUID();

    document.cookie = `${`UUID`}=${UUID};domain=${domain};`;

    let rakutenPropertiesExists = true;
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 90);
    for (const [key, value] of Object.entries(rakutenQueryParams)) {
        if (value && value !== getCookie(key)) {
            rakutenPropertiesExists = false;
        }
        if (value) {
            document.cookie = `${key}=${value};domain=${domain};expires=${expiryDate}`;
        }
    }

    if (!rakutenPropertiesExists) {
        document.cookie = `time_entered=${timeEntered};domain=${domain};expires=${expiryDate}`;
    }
}

export function oneLinkRedirection() {
    const oneLink = window.oneLinkRedirectionLink;
    window.open(oneLink, "_blank");
}

export const isValidURL = (string) => {
    let url;

    try {
        url = new URL(string);
    }
    catch (error) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const currencyInput = {
    'INR': { prefix: '₹', separatorStyle: 'lakh' },
    'USD': { prefix: '$', separatorStyle: 'thousand' },
};

export const currencyToLocale = {
    INR: 'en-IN',
    USD: 'en-US',
    JPY: 'ja-JP',
    VND: 'vi',
    AUD: 'en-AU',
};

export const Debounce = (func, timeout = 200) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const isDesktopView = () => {
    if(isBrowser()){
        return window?.innerWidth > 576
    }
}

export const convertStringToDate = (data) => {
    var date = new Date(data);
    var finaldate = date.getDate() + '-' +  (date.getMonth() + 1)  + '-' +  date.getFullYear();

    return finaldate;
}

export function isInViewport(element,threshold=35) {
    const rect = element.getBoundingClientRect();
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    const viewportArea = viewportWidth * viewportHeight;

    const intersectionArea =
        Math.max(0, Math.min(rect.right, viewportWidth) - Math.max(rect.left, 0)) *
        Math.max(0, Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0));

    const percentageVisible = (intersectionArea / viewportArea) * 100;

    const visibilityThreshold = threshold;

    return percentageVisible >= visibilityThreshold;
}

export const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(isBrowser() && window?.location?.search);
    return queryParams.get(param);
};
