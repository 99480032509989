
//Navigation
export const NAV_ITEM = 'nav';
export const NAV_DROP = 'dropdown';

//Products
export const PRODUCTS = {
    "classic": "classic",
    "signature": "signature",
    "black": "black"
}
export const PRODUCTS_PATH = ["classic", "black", "signature"]

export const PRODUCTS_ID = {
    'black': 0,
    "classic": 1,
    "signature": 2
}

export const BLACKLISTED_PATH = [
    "auto"
]

export const CUSTOM_HEADERS_PATH = [
    {
        "name": "remittance"
    }
]

export const DEFAULT_ONELINK = "https://onelink.to/57tcc9";

export const INSURANCE_PATH = ["renter", "health", "pet"];
export const LOAN_PATH = ["auto"];

export const REDIRECTION_PATHS = [
    "azpire",
    "credit-score",
    "financial-terms",
    'credit-card'
];

export const TERMS_AND_CONDITIONS_PAGES = [
    "cashback-tnc",
    "esign-and-communications",
    "boost-tnc",
    "privacy-policy",
    "credit-cardholder-agreement"
]

export const CURRENCY_TYPES = {
    INR: "INR",
    USD: "USD"
}

export const MIN_AGE_REQUIREMENT = 18;

export const COMPONENT = {
    TEXT: 'text',
    PHONE: 'phone',
    DATE: 'date',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    SELECT: 'select',
    FILE: 'file',
};

export const INSURANCE_SEO_DATA = {
    renter: {
        name: 'Renters Insurance Plans',
        title: 'Renters Insurance USA: Affordable Renter Insurance Plans, Cost | Zolve',
        description: 'Get affordable renters insurance plans in the USA to safeguard your valuable household items against the unforeseen. Compare rates and coverage options today.',
        keywords: 'renters insurance, renters insurance usa, usa renters insurance, renters insurance, best renters insurance, affordable renters insurance renters insurance plans, renters insurance without ssn',
        path: 'insurance/renter',
        faqs: [
            {
                "@type": "Question",
                "name": "Why should I buy Renters Insurance in the USA from Zolve?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Renters Insurance covers the valuable belongings in your household. In addition these plans are very affordable with the premiums starting at $5 a month"
                }
            },
            {
                "@type": "Question",
                "name": "What all does the Renters Insurance cover?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Renters Insurance covers your household items. Depending on the plan the coverage maybe against damage, theft and other natural calamities"
                }
            },
            {
                "@type": "Question",
                "name": "How do I get refunds and claims?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You could request for refund or claims directly from the Insurance provider website"
                }
            },
            {
                "@type": "Question",
                "name": "I am not a student, can I buy these plans?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes you could buy the plan for yourself or your dependants"
                }
            }
        ],
    },
    health: {
        name: 'Health Insurance in USA',
        title: 'Health Insurance USA: Buy Affordable Health Insurance for Students & Professionals',
        description: 'Buy affordable health insurance with Zolve in the USA for students and professionals. Compare plans, coverage, and find the right policy for your needs today.',
        keywords: 'USA healthcare insurance, health insurance for usa, USA healthcare industry, student health insurance usa, health insurance without ssn, health insurance for international students in usa, health insurance for visitors to usa from india',
        path: 'insurance/health',
        faqs: [
            {
                "@type": "Question",
                "name": "Why should I avail Health Insurance while staying in the USA from Zolve?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Average Healthcare cost in the US is around $12k and it is recommended that you cover yourself and your dependants staying in the US with a trusted health insurance company"
                }
            },
            {
                "@type": "Question",
                "name": "What is a University waiver process?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Some Universities make it mandatory to buy health Insurance from their campus. In such a situation it is best to buy health insurance from campus. However a lot of Universities allow students to buy superior and affordable plans from outside the campus. If already bought, you can apply for waiver from the University"
                }
            },
            {
                "@type": "Question",
                "name": "Is the waiver process cumbersome?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Getting a waiver is very easy. Visit your University website and send all the insurance coverage documents to the designated email ID"
                }
            },
            {
                "@type": "Question",
                "name": "I bought the plan but the University hasn’t accepted it.",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our referred partners ISO and SMC allow you to upgrade the insurance to match the University criteria. They also allow you to claim a refund within 30 days of your University confirming the rejection on your email"
                }
            },
            {
                "@type": "Question",
                "name": "How do I get refunds and claims?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can request a refund or submit claims directly from the ISO or SMC website"
                }
            },
            {
                "@type": "Question",
                "name": "I am not a student, can I buy these plans?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes you can buy these plan for yourself or your dependants"
                }
            }
        ],
    },
    pet: {
        name: 'Pet Insurance in USA',
        title: 'Buy Pet Insurance in USA | Pet Insurance plans for Dogs & Cats | Zolve',
        description: 'Buy Pet Insurance in the USA from Zolve. Protect your pet’s health and well-being against health issues. Get affordable plans without SSN and more. Apply today.',
        keywords: 'pet insurance, pet insurance cost, pet insurance online, pet insurance in usa, pet insurance usa, pet insurance policy, pet insurance plans, pet insurance for dogs usa',
        path: 'insurance/pet',
        faqs: [
            {
                "@type": "Question",
                "name": "Why should I buy Pet Insurance from Zolve?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You should buy Pet Insurance if you have a lovely pet around you who makes your day."
                }
            },
            {
                "@type": "Question",
                "name": "What all does the Pet Insurance cover from ?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Pet Insurance covers your cat or dog items. Depending on the plan the coverage maybe for Diagnostics, Medication, Essential care and even Procedures"
                }
            },
            {
                "@type": "Question",
                "name": "How do I get refunds and Claims?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You could request for refund or Claims directly from the Insurance provider website"
                }
            }
        ],
    },
};

export const LOAN_SEO_DATA = {
    auto: {
        name: 'Car Loans in USA for Indians',
        title: 'Car Loan in USA for Indians – Apply Online for Best Auto Loan in the USA| Zolve',
        description: 'Find out how Zolve helps Indians get car loans in the USA with an easy repayment method. Check ✓Required Documents, ✓Loan Highlights, ✓EMI Calculator, ✓Application Process. Apply Now!',
        keywords: 'car insurance in usa, us auto loan, auto insurance for usa',
        path: 'loan/auto',
        faqs: [
            {
                "@type": "Question",
                "name": "Why should I buy a car using an auto loan?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Auto loans help you own your dream car without having to worry about paying for it upfront except the down payment. You can use the money saved in your bank account to invest and earn interest from them as well and not worry about having to keep up with your expenses."
                }
            },
            {
                "@type": "Question",
                "name": "Why should I get an auto loan on Zolve?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Zolve has partnered with Lendbuzz - an auto loan provider that offers affordable auto loans to students or individuals with no credit history or thin credit. You do not require an SSN to apply for auto loans. Lendbuzz offers an exclusive 1% discount on your loan’s APR to all Zolve customers."
                }
            },
            {
                "@type": "Question",
                "name": "Do I need to buy a car directly from a dealership?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can purchase your vehicle from either a dealership or a private party (used car owner). The details of a private party transaction can be discussed with the Lendbuzz sales representative assigned to your application."
                }
            },
            {
                "@type": "Question",
                "name": "Can I buy a used car from a private-party?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can purchase your vehicle from either a dealership or a private party (example - found on Facebook Marketplace). The details of a private party transaction can be discussed with the Lendbuzz sales representative assigned to your application. Lendbuzz will offer an auto loan to you as long as the vehicle is less than 9 years old and driven less than 120,000 miles."
                }
            },
            {
                "@type": "Question",
                "name": "What is the process to get an auto loan from Lendbuzz?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can follow the below steps to get an auto loan\n1. Click on Apply Now on the Zolve App :  you will be redirected to the Lendbuzz web page where you need to fill out the loan application form. \n2. Fill the loan application form : This form covers some relevant details like loan requirement, education, etc. \n3. Get your pre-approval : After submitting this form, you will receive a preliminary approval email with an initial quotation\n4. Connect your bank account : You will receive a text message from Lendbuzz with a link to connect your bank account. This step is critical as Lendbuzz connects to your bank account via Plaid to further process your application\n5. Document upload - You will be required to upload a few documents to validate the information on the application form\n6. Pick out your car - After this, you are required to pick out your car. Your loan can not be sanctioned unless you add a VIN to your application\n7. Buy your car - This is the last step. A Lendbuzz representative will be assigned to you prior to this step. He will work with you and the dealership to procure any final documents and help you with the loan disbursal"
                }
            },
            {
                "@type": "Question",
                "name": "What documents will I require?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Based on your profile, Lendbuzz may ask you to produce one or more of the below documents\n\n\n1. Proof of Identity - Copy of Passport\n2. Proof of immigration - Copy of Visa (for non-residents in the USA)\n3. Proof of studying the USA - Copy of i20 document (for students)\n4. Proof of Income - Job Offer Letter (for working professionals)\n5. Proof of residence (last 90 days) - Phone bill / Electricity bill\n6. Car registration application\n7. Proof of auto insurance"
                }
            },
            {
                "@type": "Question",
                "name": "What is the APR that will be charged if I take a loan from Lendbuzz?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Lendbuzz offers loans with APR starting from 7.2%. But every user will get a different rate based on user’s profile and data. In order to get your rate, you will have to make the application by clicking on ‘Apply Now’. A pre-approval email will be sent to you which will include the rate at which you will get the loan."
                }
            },
            {
                "@type": "Question",
                "name": "How does the 1% extra discount get applied on Lendbuzz?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You get an exclusive 1% discount on the APR you pay on your loans if you apply for the auto loan through Zolve's app. For example, if you are getting a 7% APR on your loan when you apply directly on Lendbuzz’s platform, you will actually get a 6% APR on the same loan if you apply through Zolve’s app"
                }
            },
            {
                "@type": "Question",
                "name": "How much down-payment do I have to make?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You will have to make a down-payment to the dealer or the private-party from which you will purchase the car. You may be asked to make a down-payment of anywhere between 10% - 30% of the loan amount."
                }
            },
            {
                "@type": "Question",
                "name": "Can I make the application before I choose my car?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you can get a pre-approval even before you choose your car. However, to get a final approval, your application needs to have a VIN number. The Vehicle Identification Number or the VIN number is a unique number of the car that you are purchasing."
                }
            },
            {
                "@type": "Question",
                "name": "Can I pay off my loan early?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you can pay your loan early. There are no foreclosure charges. You only need to pay the interest for the duration that you took the loan."
                }
            },
            {
                "@type": "Question",
                "name": "Why did my loan get denied?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Currently, Lendbuzz is not licensed to offer loans in the following states - Alabama, Alaska, Delaware, Hawaii, Idaho, Kentucky, Louisiana, Minnesota, Mississippi, Montana, Nevada, North Dakota, Oklahoma, Rhode Island, South Dakota, Vermont, West Virginia, Washington D.C., and Wyoming. If you belong to any of these states, Lendbuzz will not be able to approve the loan for you."
                }
            },
            {
                "@type": "Question",
                "name": "What do I do if I face any issue with the application (technical issue, missed communication, etc) ?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "In case you have any issues with you application, you can discuss with the Lendbuzz representative assigned to your application. Alternatively, you can raise a concern with Lendbuzz customer support. If you are unable to find a satisfactory response, please send an email to autoloan.support@zolve.com via your registered email id. Share your Lendbuzz application id and explain the issue in detail with relevant screenshots."
                }
            }
        ],
    },
};

export const REGEX = {
    BLOG_REMOVE_ID: /{#([\w-]+\??)}/g,
    BLOG_EXCTRACT_ID : /{#([^}]+\??)}/
}

export const CUSTOM_QR_PATHS =[
    'visa-folder',
    'scholarship'
]
