import React, { useState } from 'react'

export default function Dropdown({
    title = ' ',
    children,
    id,
    isExpanded = true,
}) {
    const [expanded, setExpanded] = useState(isExpanded);

    return (
        <div className='accordion-2__accordion nav-accordion'>
            <input
                id={id}
                type='checkbox'
                checked={expanded}
                onChange={() => setExpanded(!expanded)}
            />
            <label
                className={
                    `accordion-2__accordion__label accordion-2__accordion__label--default nav-accordion__label`
                }
                htmlFor={id}
            >
                {title}
            </label>
            <div
                className='accordion-2__accordion__content nav-accordion__content'
            >
                {children}
            </div>
        </div>
    );
}
